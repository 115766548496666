import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Page from "../components/page";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    siteMeta: site {
      siteMetadata {
        siteUrl
      }
    }
    page: sanityPage(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        ...SanityImage
        alt
      }
      useMainImageOnlyForSharing
      title
      metaTitle
      metaDescription
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  const fullUrl =
    data &&
    data.siteMeta &&
    data.siteMeta.siteMetadata &&
    `${data.siteMeta.siteMetadata.siteUrl}/${page.slug.current}`;

  return (
    <Layout>
      {errors && <SEO metaTitle="GraphQL Error" />}
      {page && (
        <SEO
          lang={"nl"}
          fullUrl={fullUrl}
          metaTitle={page.metaTitle}
          metaDescription={page.metaDescription}
          image={page.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {page && <Page {...page} />}
    </Layout>
  );
};

export default PageTemplate;

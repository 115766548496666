import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import stylesMain from "./main.module.css";
import Container from "./container";
import PortableText from "./portableText";

function BlogPost(props) {
  const { _rawBody, title, mainImage, useMainImageOnlyForSharing } = props;
  return (
    <div>
      {!useMainImageOnlyForSharing && mainImage && mainImage.asset && (
        <div className={stylesMain.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(960)
              .height(Math.floor((9 / 16) * 960))
              .fit("crop")
              .auto("format")
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={stylesMain.mainContent}>
          <h1 className={stylesMain.title}>{title}</h1>
          <div></div>
          {_rawBody && <PortableText blocks={_rawBody} />}
        </div>
      </Container>
    </div>
  );
}

export default BlogPost;
